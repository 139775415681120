<template>
  <div class="container">

    <!-- BUBBLE BG -->
    <div class="bubbles bubble-1"></div>
    <div class="bubbles bubble-5"></div>
    <div class="bubbles bubble-2"></div>
    <div class="bubbles bubble-6"></div>
    <div class="bubbles bubble-3"></div>
    <div class="bubbles bubble-7"></div>
    <div class="bubbles bubble-4"></div>
    <div class="bubbles bubble-8"></div>

    <div class="animated fadeInLeft">
        <div class="window">
            <!-- Work Header -->
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">Sorry, the page you requested cannot be found.</div>

                    <router-link to="/">
                    <div class="button">Go back</div>
                    </router-link>
                   
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'

export default {
  name: 'NotFoundCompPage',
  components: {
    FooterDiv
  },
  mounted(){
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');

a{
  color:#2c3e50;
}

.container{
  font-family:poppins;
  height: 100vh;
  width: 90vw;
  top: 0;
  left: 0;
  background-color: white;
  overflow:scroll;
  overflow-x:hidden;
}

.window{
  display:flex;
  justify-content: center;
  height:90vh;
  width: 100%;
  margin:auto;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

.inside-cont{
  width: 70%;
  display:flex;
  justify-content: center;
  margin:auto;
}

.button{
  display:inline-block;
  position:relative;
  margin-top:40px;
  width:10vw;
  padding:2%;
  border: 2px solid #f49b90;
  border-radius:20px;
  font-size:1em;
  text-align:center;
}

.button:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}

.header{
  font-family: fredoka one;
  font-size:2.5em;
  color:#424949;
  text-align: center;
}

/* Bubbles */
.bubbles {
  position: absolute;
  bottom: 0;
  background-color: #f49b90;
  border-radius: 50%;
  opacity: .50;
  width: 20px;
  height: 20px;
  animation: up 4s infinite;
}

.bubble-2 {
  left: 350px;
  animation: up 2s infinite;
}

.bubble-3 {
  left: 750px;
  opacity: .30;
  animation: up 5s infinite;
}

.bubble-4 {
  left: 1150px;
  animation: up 6s infinite;
}

.bubble-5 {
  left: 50px;
  animation: up 5s infinite;
}

.bubble-6 {
  left: 300px;
  opacity: .30;
  animation: up 3s infinite;
}

.bubble-7 {
  left: 670px;
  animation: up 4s infinite;
}

.bubble-8 {
  left: 1050px;
  opacity: .30;
  animation: up 3s infinite;
}


@keyframes up-scroll { 
    0% {
    transform: translateY(0px);
  }
    to {
    transform: translateY(-100%)
  }
}

@keyframes down-scroll { 
    0% {
    transform: translateY(-100%);
  }
    to {
    transform: translateY(0%)
  }
}


@keyframes up {
  100% {
    transform: translateY(-800px);
  }
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 #f49b90;
  }
  20% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d;
  }
  30% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
  }
  40% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  50% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  60% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  70% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
  }
  80% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d;
  }
  90% {
    text-shadow: 3px 3px 0 #f49b90;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}


@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    height:50vh;
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }

  .header{
    font-size:2em;
  }

  .button{
    font-size:1em;
    width:25vw;
    padding:3%;
  }


}

</style>

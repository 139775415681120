<template>
  <div id="container">
    <NotFoundCompPage/>
    <NavBar/>
  </div>
</template>

<script>
import NotFoundCompPage from '@/components/NotFoundCompPage.vue'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'NotFoundPage',
  components: {
    NotFoundCompPage,
    NavBar
}
}
</script>

<style scoped>
#container{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
</style>

<template>
  <div id="container">
    <ContentAngelsScent/>
    <NavBar/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import ContentAngelsScent from '@/components/ContentAngelsScent.vue'

export default {
  name: 'AboutPage',
  components: {
    ContentAngelsScent,
    NavBar
}
}
</script>

<style scoped>
#container{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
</style>


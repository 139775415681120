<template>
    <div class="wrap">
        <div class="left-text">
            Personally handcrafted with VueJs. <br>
            Copyright © Nicole Ocampo 2022
        </div>
        
        <div class="center-text">
            <router-link to="/contact">
                <img src="@/assets/email-icon.png" width="30" height="30" alt="Mail">
            </router-link>

            <a v-bind:href="`https://www.linkedin.com/in/andrea-nicole-o-75079a168/`" target="_blank">
                <img src="@/assets/linkedin-icon.png" width="30" height="30" alt="LinkedIn">
            </a>

            <a v-bind:href="`https://github.com/nicole-ocampo`" target="_blank">
                <img src="@/assets/github-icon.png" width="30" height="30" alt="GitHub">
            </a>

            <a v-bind:href="`https://medium.com/@nicole-ocampo`" target="_blank">
                <img src="@/assets/medium-icon.png" width="30" height="30" alt="Medium">
            </a>

        </div>
    </div>
</template>

<script>
export default {
  name: 'FooterDiv'
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");

.wrap{
    height:15vh;
    background:#FFC4BD;
    position:relative;
    bottom:0;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
}

.left-text{
    text-align:left;
    width:auto;
    padding-top:2%;
    margin-left: 5%;
    font-family:poppins;
    font-size:0.75em;
    color:white;
}

.center-text{
    margin-top:2%;
    width:25vw;
    height:auto;
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}


.center-text img:hover{
    animation: tada 1s forwards;
}

img{
    margin:3%;
}

@media (max-width: 670px) {
    .wrap{
        height:20%;
        display:flex;
        flex-direction:column;
        justify-content:center;
    }

    .center-text{
        margin:auto;
        width:50vw;
    }

    .left-text{
        margin:auto;
        text-align:center;
        width:auto;
        padding-top:0%;
    }
 
}
</style>

<template>
  <div class="container">
    
    <!-- BUBBLE BG -->
    <div class="bubbles bubble-1"></div>
    <div class="bubbles bubble-5"></div>
    <div class="bubbles bubble-2"></div>
    <div class="bubbles bubble-6"></div>
    <div class="bubbles bubble-3"></div>
    <div class="bubbles bubble-7"></div>
    <div class="bubbles bubble-4"></div>
    <div class="bubbles bubble-8"></div>

    <div id="contain-all">
      <!-- FIRST WINDOW  @click="$router.push('about')"-->
      <div class="window">
        <div id="wrap1">
          <div class="main-text"> 
            Hi, I'm <div class="text-shadows">Nicole</div>
            <div class="short-desc">
              I'm a <highlight>software engineer</highlight> based in the Philippines.  
              I have a passion for creating immersive <highlight>digital solutions</highlight>that cater to 
              <highlight>user experience</highlight> through design and microinteractions. 
            </div>

            <router-link to="/contact">
              <div class="button"> Let's work together! </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- SECOND WINDOW -->
      <div class="window"> 
          <div id="wrap2">
            <div class="work-desc-wrapper">
              <div class="header">My recent works</div> 
              These are some projects that I have recently worked on. My main tasks primarily involve
              <highlight><b>user research</b></highlight> that caters to <highlight><b>user experience</b></highlight>,
              and <highlight><b>full-stack development</b></highlight>. Apart from software building, I also have 
              projects focusing on <highlight><b>data analysis</b></highlight> and <highlight><b>machine learning</b></highlight>.

              <br><br>

              <router-link to="/works">
              <div class="work-desc-button">Learn more</div>
              </router-link>
            </div>

            <div class="work-wrapper">
                <!-- WORK 1 -->
                <router-link to="/angelsscent">
                <div class="sample-work">
                  <img src="@/assets/workbanners/1.png" width="100" height="100" alt="Sample work 1">
                </div>
                </router-link>
                
                <!-- WORK 2 -->
                <router-link to="/animatch">
                <div class="sample-work">
                  <img src="@/assets/workbanners/2.png" width="100" height="100" alt="Sample work 2">
                </div>
                </router-link>

            </div>

          </div>

      </div>
    </div>


    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'

export default {
  name: 'ContentPage',
  components: {
    FooterDiv
  },
  mounted(){
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');

html {
  scroll-behavior: smooth;
}

a{
  color:#2c3e50;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  background-color: white;
  overflow:scroll;
  overflow-x:hidden;
}

#contain-all{
  display:block;
  height: auto;
  width: 90vw;
  top: 0;
  left: 0;
  animation: fadeInLeft 1.5s;
}

.window{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}


::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

#wrap1{
  display:inline-block;
  width:100vw;
  height:auto;
  padding-bottom:10vh;
}

.main-text{
  display:block;
  overflow:hidden;
  font-family: fredoka one, sans-serif;
  font-size: 4.5em;
  width:auto;
  margin-top:17%;
  margin-left:4%;
  padding-left:3%;
  text-align:left;
  color: #424949;
}

highlight{
  background-color: #f49b90;
  color: #fff;
  padding-left:1%;
  padding-right:1%;
}

.button{
  position:relative;
  margin-top:20px;
  display:inline-block;
  padding:1.5%;
  border: 2px solid #f49b90;
  border-radius:20px;
  font-size:0.25em;
  text-align:center;
}

.button:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}

.short-desc{
  display:block;
  overflow:hidden;
  width:55vw;
  margin-top:3vh;
  font-family: fredoka one, sans-serif;
  font-size:0.33em;
  line-height:1.5em;
}

.text-shadows {
  text-shadow: 3px 3px 0 #f49b90, 6px 6px 0 #f28b7d,
               9px 9px  #f07a6a, 12px 12px 0  #ee6352;
  font-family: fredoka one, sans-serif;
  text-transform: uppercase;
  font-size: 1em;
  color: #f6aca2;
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

#wrap{
  width:90vw;
  height:auto;
  display:flex;
  flex-direction:row;
}

#text-wrapper{
  width:35vw;
  margin-top:20%;
  display:inline-block;
}


.header{
  font-family: fredoka one;
  font-size:1.8em;
  color:#424949;
  line-height:2.5em;
}

#wrap2{
  width:90vw;
  padding-bottom:5%;
  height:auto;
  display:flex;
  flex-direction:row-reverse;
}

.work-wrapper{
  padding-left:2%;
  padding-right:2%;
  display:flex;
  flex-direction:column;
  width: fit-content;
}


.work-desc-wrapper{
  padding:5%;
  width:35vw;
  text-align:left;
  font-family:poppins;
  font-size:1em;
  color:#424949;
}

.work-desc-button{
  position:relative;
  border:1px solid #f49b90;
  display:inline-block;
  padding:3%;
  font-family:fredoka one;
  font-size:1em;
  border-radius:20px;
  color:#424949;
}


.work-desc-button:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}

.sample-work{
  width:45vw;
  height:18vw;
  margin-top:2.5%;
  margin-bottom:2.5%;
  border-radius:20px;
  overflow:hidden;
  position:relative;
}

.sample-work:hover{
  cursor:pointer;
  animation: pulse 1s;
}

.sample-work img {
  object-fit: cover;
  width:inherit;
  height:inherit;  
}

/* For mobile */
@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  /* Window 1 */
  #wrap1{
    height:65vh;
  }

  .main-text{
    margin-top:20vh;
    font-size:2.8em;
  }

  .button{
    padding:3%;
  }

  .short-desc{
    width:85vw;
    margin-top:3vh;
    font-size:0.4em;
    line-height:1.5em;
  }

  /* Window 2 */
  #wrap2{
    margin-top:5%;
    padding-bottom:15%;
    flex-direction: column;
  }

  .work-desc-wrapper{
    margin-top:0vh;
    margin-left:3vw;
    width:85vw;
    font-size:0.8em;
  }

  .header{
    font-size:2em;
  }

  .work-desc-button{
    padding:3%;
  }

  .sample-work{
    margin-left:3%;
    width:90vw;
    height:20vh;
  }
}

/* Bubbles */
.bubbles {
  position: absolute;
  bottom: 0;
  background-color: #f49b90;
  border-radius: 50%;
  opacity: .50;
  width: 20px;
  height: 20px;
  animation: up 4s infinite;
}

.bubble-2 {
  left: 350px;
  animation: up 2s infinite;
}

.bubble-3 {
  left: 750px;
  opacity: .30;
  animation: up 5s infinite;
}

.bubble-4 {
  left: 1150px;
  animation: up 6s infinite;
}

.bubble-5 {
  left: 50px;
  animation: up 5s infinite;
}

.bubble-6 {
  left: 300px;
  opacity: .30;
  animation: up 3s infinite;
}

.bubble-7 {
  left: 670px;
  animation: up 4s infinite;
}

.bubble-8 {
  left: 1050px;
  opacity: .30;
  animation: up 3s infinite;
}


@keyframes up-scroll { 
    0% {
    transform: translateY(0px);
  }
    to {
    transform: translateY(-100%)
  }
}

@keyframes down-scroll { 
    0% {
    transform: translateY(-100%);
  }
    to {
    transform: translateY(0%)
  }
}


@keyframes up {
  100% {
    transform: translateY(-800px);
  }
}

@keyframes shadows {
  0% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 #f49b90;
  }
  20% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d;
  }
  30% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
  }
  40% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  50% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  60% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
      12px 12px 0  #ee6352;
  }
  70% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
  }
  80% {
    text-shadow: 3px 3px 0 #f49b90,
      6px 6px 0 #f28b7d;
  }
  90% {
    text-shadow: 3px 3px 0 #f49b90;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes move {
  0% {
    transform: translate(0px, 0px);
  }
  40% {
    transform: translate(-12px, -12px);
  }
  50% {
    transform: translate(-12px, -12px);
  }
  60% {
    transform: translate(-12px, -12px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

</style>

<!-- References:

-- Growing Text Animation
https://codepen.io/alvarotrigo/pen/dyzxgOO

-- Bubbles Animation
https://codepen.io/jonitrythall/pen/DBeeqJ

-- Text Scramble
https://codepen.io/soulwire/pen/mEMPrK

 -->
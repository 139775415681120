<template>
  <div class="container">
    <div class="line"></div>
    <div class="box">
        <div class="icon-box">
            <router-link to="/" id="home-icon">
            <span class="material-icons-round">home</span>
            </router-link>
            <div class="icon-label" id="home-label">home</div>
        </div>

        <div class="icon-box">
            <router-link to="/works" id="projects-icon">
            <span class="material-icons-round">menu</span>
            </router-link>
            <div class="icon-label" id="projects-label">projects</div>
        </div>

        <div class="icon-box">
            <!-- <span class="material-icons-round" id="art-icon">auto_awesome</span> -->
            <router-link to="/contact" id="art-icon">
            <span class="material-icons-round">mail</span>
            </router-link>

            <div class="icon-label" id="art-label">contact</div>
        </div>

        <div class="icon-box">
            <router-link to="/about" id="about-icon">
            <span class="material-icons-round">person</span>
            </router-link>
            <div class="icon-label" id="about-label">about</div>
        </div> <br><br>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");


.container{
    height: 100%;
    width: 10%;
    top: 0px;
    right: 0px;
    background-color: white;
    display:flex;
    flex-direction: row;
}

.line{
    height:100%;
    width: 3px;
    background-color:#e5e8e8;
    top: 0px;
    left:0px;
    -webkit-animation: slide-from-top 1.5s cubic-bezier(.215, .61, .355, 1) forwards;
    animation: slide-from-top 1.5s cubic-bezier(.215, .61, .355, 1) forwards;
}

.box{
    height: 100%;
    width: 10%;
    padding:15px;
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
}

.material-icons-round{
    padding-bottom:20px;
    color: #FFC4BD;
}

.material-icons-round:hover{
    padding-bottom:20px;
    color: #ee6352;
}

.icon-box{
    width:auto;
    padding:5px;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    -webkit-animation: slide-from-bottom 1.5s cubic-bezier(.215, .61, .355, 1) forwards;
    animation: slide-from-bottom 1.5s cubic-bezier(.215, .61, .355, 1) forwards;
}
.icon-label{
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    color:#f49b90;
    text-transform: uppercase;
    letter-spacing:1px;
    margin-left:10px;
    margin-top:6px;
}


#home-label{
    display:none;
}

#home-icon:hover + #home-label{
    display:block;
}

#projects-label{
    display:none;
}

#projects-icon:hover + #projects-label{
    display:block;
}

#art-label{
    display:none;
}

#art-icon:hover + #art-label{
    display:block;
}

#about-label{
    display:none;
}

#about-icon:hover + #about-label{
    display:block;
}

/* For mobile */
@media (max-width: 670px) {
    .container{
        position:fixed;
        width: 100%;
        height:auto;
        padding-right:10%;
        top: 0px;
        left: 0px;
        background-color: white;
        display:flex;
        border:2px solid #e5e8e8;
    }

    .icon-box{
        height:25px;
        animation: fadeInLeft 1.5s;
    }

    .box{
        width:100%;
        display:flex;
        flex-direction:row;
    }

    #home-label{
        display:block;
    }

    #home-icon:hover + #home-label{
        color: #ee6352;
    }

    #projects-label{
        display:block;
    }

    #projects-icon:hover + #projects-label{
        color: #ee6352;
    }

    #art-label{
        display:block;
    }

    #art-icon:hover + #art-label{
        color: #ee6352;
    }
    
    #about-label{
        display:block;
    }

    #about-icon:hover + #about-label{
        color: #ee6352;
    }

    .line{
        display:none;
    }
}


/* Animations */
@keyframes slide-from-top {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}

@keyframes slide-from-bottom {
    0% {
        -webkit-transform: translateY(400%);
        transform: translateY(400%)
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%)
    }
}


</style>

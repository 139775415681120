<template>
  <div class="container">
    <div class="animated fadeInLeft">
        <!-- HEADER -->
        <div class="img-header"></div>

        <!-- CONTENT -->
        <div class="window">
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">Parallel Image Filtering</div> <br>

                    
                    <!-- Role Overview-->
                    <div class="inside-in-cont-header">Role Overview</div> <br>
                    <i>
                    <b>Project Time:</b>  November 2022 - December 2022 <br>
                    <b>Role:</b>  Developer <br>
                    <b>Language:</b>  Python <br>
                    <b>Dependencies:</b>  PIL (Pillow) <br><br>
                    </i>

                    I worked closely with a partner as a developer to create this application for a school project.
                    What makes this different from your standard image filtering program, is that this implementation
                    employs parallelism techniques. This enables the program to process more images at a lesser time.
                    
                    <br><br>

                    <!-- Concept -->
                    <div class="inside-in-cont-header">Concept</div> <br>
                    Although it may seem like adjusting images is as simple as adding numbers to the current values of the image, a lot more is going on. Thousands of pixels are being manipulated. This could take quite some time for the process to finish, especially for huge images and multiple queries, where multiprocessing and threading come into play. Usually, machines read instructions sequentially, but parallel programming techniques allow the machine to run multiple instructions simultaneously, leading to remarkable performance gains.

                    <br><br>
                    This project focuses on designing and implementing an Image Enhancement program using parallel programming to improve performance and efficiency. The program takes the following input from the user:
                    
                    <ol>
                      <li>Folder location of images </li>
                      <li>Folder location of enhanced images </li>
                      <li>Enhancing time in minutes </li>
                      <li>Brightness enhancement factor </li>
                      <li>Sharpness enhancement factor </li>
                      <li>Contrast enhancement factor </li>
                    </ol>
                    
                    <br>
                    Once the input has been parsed, the program will enhance the images according to the input enhancement factors, and will be outputted in the folder location for processed images. A text file containing summary statistics will also be generated for further analysis.

                    <br><br><br>


                    <!-- Task Decomposition, Task Assignment, and Agglomeration -->
                    <div class="inside-in-cont-header">Task Decomposition, Task Assignment, and Agglomeration</div> <br>

                    <img src="@/assets/parallelimage/1.png">
                    <br><br>

                    The foundation of the implementation is heavily based on the producer-consumer problem as illustrated below. The program has two main tasks, with each task assigned to a child process: (1) to retrieve the input, and; (2) to process the images. The producer is in charge of retrieving the images from its folder, and parsing the image in preparation for processing. Once done, the producer will append the parsed images to the shared resource buffer. On the other hand, the consumer is in charge of processing each image from said buffer and outputs them to their respective folder. In essence, each process will be handling the same amount of data but with different tasks.

                    <br><br>
                    <img src="@/assets/parallelimage/2.png">

                    <br><br>

                    The number of tasks is known to increase as the problem size increases. Ideally, each task can be provided with one worker process to improve performance. However, interprocess communication can also cause overhead and slow the program down. It is important to group smaller tasks together to increase the locality of the algorithm and minimize the possibility of a performance decline.  Thus, the job of the consumer process can be further divided into three (3) sub tasks:

                    <ol>
                      <li> 
                        <b>Stage 1: Application of Brightness Filter: </b> 
                        enhances the image according to the input brightness enhancement factor
                      </li>
                      <li> 
                        <b>Stage 2: Application of Contrast Filter:  </b> 
                        enhances the image according to the input contrast enhancement factor
                      </li>
                      <li> 
                        <b>Stage 3: Application of Sharpness Filter: </b> 
                        enhances the image according to the input sharpness enhancement factor
                      </li>
                    </ol>

                    <br>
                    As previously discussed, once the producer retrieves an image, it also parses the image and transforms the input into a usable format for processing. With this, it can be assumed that the consumer already receives a clean input which removes the need for adding cleaning as a separate sub task.

                    <br><br>
                    In this scenario, the input represents an item retrieved from the shared resource buffer. Each stage is assigned a separate worker thread to divide the workload for manipulating the image within the consumer process. Once each worker thread is done with their task, they join the consumer process again and the image is outputted to the provided location for processed images. 

                    <br><br><br>

                    <!-- Mapping -->
                    <div class="inside-in-cont-header">Mapping</div> <br>
                    Multiprocessing uses multiple processors to execute processes concurrently. Each process runs in parallel with each other, but with no determined order. This means the processes will run in no fixed time and will finish in no particular order. Multiprocessing puts multiple processors to use, and each of these processors can run instructions while another does the same. With multiple processors working in parallel, completion of multiple tasks would be much faster as compared to just one processor working on several tasks. The Image Enhancer problem essentially has a static number of tasks. With this, a structured communication was chosen as a mapping heuristic.

                    <br><br>
                    <img src="@/assets/parallelimage/3.png">

                    <br><br>
                    In a structured communication, one task will be assigned to each available processor as seen in figure 5. With the given design, since tasks have already been agglomerated in the previous stages of the design phase, each processor will only be dealing with either a producer or consumer process, but not both. Depending on the number of available processors, it is possible to increase the number of consumer processes and let them work on subsections of the dataset. The given diagram illustrates how each process will be mapped in a quad-core machine.

                    <br><br><br>


                    <!-- Results-->
                    <div class="inside-in-cont-header">Results</div> <br>
                    To test the speed and efficiency of our design, we have prepared a set of 60 images in .jpg and .png format. We have used the same input for the succeeding requirements to maintain consistency and minimize the factors that could affect the experiment results. It is also important to note that the machine we used to run the program has a six-core processor. 

                    <br><br>
                    <img src="@/assets/parallelimage/4.png">
                    
                    <br><br>
                    To have a baseline comparison, we first tested the same image enhancement program with no parallelism techniques involved. We ran the same program five times and computed the mean execution time in seconds, and average CPU usage. The sequential implementation was able to successfully manipulate an average of 27.8 images in 6.07 seconds, with an average 14.07% CPU usage. 

                    <br><br>

                    <img src="@/assets/parallelimage/5.png">

                    <br><br>
                    We then ran the Image Enhancement program using parallel programming techniques, but only utilized two processors at most. To do this, we spawned one process for the producer, and one process for the consumer. This implementation was able to run without any errors, and was able to enhance an average of 46.8 images for an average 6.091 seconds and 19.874 CPU usage.

                    <br><br>
                    <img src="@/assets/parallelimage/6.png">

                    <br><br>
                    Lastly, we ran the Image Enhancement program using parallel programming techniques using all of the available processors within the machine. To do this, we spawned one process for the producer, the five processes for the consumer. It is important to note that since the number of input images is 60, this means that each consumer process will be manipulating 12 images only. But since the consumers are running in parallel, it should be able to run much faster theoretically. This implementation was able to run without any errors, and was able to enhance an average of 59.8 images for an average of 2.794 seconds and 59.243 CPU usage.


                    <br><br><br>
                    <!-- Comparing speed-up-->
                    <div class="inside-in-cont-header">Comparing speed-up</div> <br>
                    <img src="@/assets/parallelimage/7.png">

                    <br><br>
                    To compare the speed-up between the three implementations, it must be ensured that each execution processes the same data to minimize the bias and inconsistencies. Thus, for this experiment, we removed the input for enhancement time temporarily. 

                    <br><br>
                    First, we measured the ratio between the execution time of the sequential implementation and the parallel implementation using two cores to calculate the speed-up improvement of the first implementation. We found that the parallel implementation was able to speed up the program 1.095x. 

                    <br><br>
                    Next, we measured the ratio between the execution time of the sequential implementation and the parallel implementation using six cores to calculate the speed-up improvement of the second implementation. We found that the second implementation was able to speed up the program 3.396x. 

                    <br><br><br>
                    <!-- Comparing efficiency-->
                    <div class="inside-in-cont-header">Comparing speed-up</div> <br>
                    <img src="@/assets/parallelimage/7.png">

                    <br><br>
                    While speedup measures how much faster a parallel implementation can be, efficiency measures how effective the program in utilizing the processors. To calculate this, we simply divide the calculated speedup to the number of cores used. Similar to the previous experiment, we also temporarily removed the input for enhancement time to ensure that each implementation will process the same set of data.

                    <br><br>
                    From here, we found that the efficiency was measured to be at 54.7% for the first implementation. This means that more than half the time, the CPU is being used by the program over the course of its execution.

                    <br><br>
                    Following the same formula, we found that the second implementation using all the available cores has an efficiency of 56.6%. Similar to the previous implementation, the CPU is being used by the program over the course of its execution more than half the time. 

                    <br><br><br>
                    <!-- Analysis-->
                    <div class="inside-in-cont-header">Analysis</div> <br>
                    To get a better grasp of the improvements, the Image Enhancer was implemented with no parallelism techniques involved as our fixed point for comparison. This variation of the Image Enhancement program – the sequential implementation – was able to manipulate an average of 27.8 images in 6.07 seconds, with an average of 14.07% CPU usage. 

                    <br><br>
                    Now using parallelism techniques, the second implementation of the Image Enhancer used two cores with the use of one for the producer process and one for the consumer process. This implementation was able to manipulate an average of 46.8 images with an average time of 6.091 seconds and a slight increase in CPU usage average coming in at 19.874%.

                    <br><br>
                    Comparing the corresponding averages of this implementation and the baseline implementation there is an improvement in terms of speed, and was 1.095x faster than our baseline. The time difference seems small in comparison to our baseline – with only around half to three-fourths of a second difference – and could perhaps be more useful with bigger data sets. The efficiency rate of this implementation averages at 16.18%.

                    <br><br>
                    The last implementation made use of more cores for the image enhancer using all the available processors in the machine, the difference from the previous implementation being the spawning of 5 consumers instead of 1. Again, by comparing the corresponding averages to the baseline, this Image Enhancer was able to manipulate an average of 59.8 images, and is considerably much faster than both the baseline and the two core implementations with an average time of 2.794 seconds and, in turn, an average CPU usage of 59.243%. It is worth noting that this implementation is 3.39x faster than the baseline. Moreover, the efficiency rate of this implementation averages at 56.6%.

                    <br><br>
                    Looking back we can see that multiprocessing improves the speed of the Image Enhancer. With the use of no multiprocessing at all, the sequential program – using only one processor for all instructions – was able to process less images as opposed to the latter implementations. Using two cores, the program was able to process a rough addition of about 20 images from the previous implementation. Although it is not at all very different in terms of speed, the difference is still notable, and would be more noticeable in bigger image sets. By scaling up the number of processors used even more, using 6 processors – all the available processors in the machine – we speed up the Image Enhancer even more. This time around, the time it took for the Image Enhancer to manipulate an average of 59.8 images averaged at a mere 2.794 seconds, which is about 3 times faster than what it initially was using just one processor. This suggests that parallelism techniques allow more workload to be done, whilst simultaneously improving the speed of the program.

                    <br><br>
                    Moreover, parallelism ensures that efficiency is implemented within the program, allowing the available processors to work on chunks of data and instructions. From the experiment, we found that the six-core implementation was averaging at 62.498% efficiency, meaning that more than half the time, the CPU is processing instructions, rather than being left at idle.

                    <br><br>
                    The efficiency ratio between the two-core and six-core implementations are roughly the same, with only around a 2% difference. However, it must be noted that this is representative of the scaling property in parallel programming. This denotes that as the problem size and the number of processors increases, the program should be able to maintain or improve the efficiency. With this in mind, the six-core implementation was able to maintain and improve the efficiency a bit, suggesting that the parallel programming techniques utilized here was able to exemplify the scalable property of parallelism.


                    <br><br><br>
                    <!-- Summary-->
                    <div class="inside-in-cont-header">Summary</div> <br>
                    In conclusion, the Image Enhancer was developed using parallelism techniques in order to improve the performance, efficiency, and speed of the algorithm. The program was developed using multiprocessing, spawning multiple processes at the same time in order to divide the workload of enhancing the input images. A producer-consumer pipeline was followed, where the producer process deals with the retrieval of images from the input file path, and the consumer processes dealing with the manipulation of images and output production. Since processes are inherently independent, a manager object was created in order to share data and allow communication between the processes. A semaphore object was also used in order to ensure that only one process enters their critical sections at a time. 
                    
                    <br><br>
                    In the case of the consumer processes, the workload was further divided using multiprogramming techniques by spawning three threads, where each thread is in charge of a specific filter (brightness, contrast, or sharpness). To synchronize this, a semaphore was also used to ensure that all filters will be applied properly.

                    <br><br>
                    All in all, parallel programming techniques enable us to run programs faster and utilize the machine much more efficiently. In the two-core processor and the six-core processor implementation, multiprocessing was primarily used to increase the speed and efficiency of the Image Enhancer, utilizing much more CPU than it initially would if executed sequentially. As we increased the number of physical cores to use, the program was able to process more images at a remarkably faster pace. In essence, parallelism allows the program to maximize the potential of the CPU itself.


                    <br><br><br>
                    <router-link to="/works">
                    <center><div class="back-btn">See more works</div></center>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'
import 'particles.js'

export default {
  name: 'ContentParallelImage',
  components: {
    FooterDiv
  },
  mounted(){
  },
  methods: {
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');


a{
  color:#2c3e50;
}

a:hover img{
  animation: tada 1s forwards;
}

video{
  width:100%;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  overflow:scroll;
  overflow-x:hidden;
  animation:fadeInLeft 0.05s;
}

.window{
  display:block;
  height: 100%;
  width: 100%;
  padding-top:5%;
  padding-left:10%;
  padding-right:10%;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}


.inside-cont{
  width: 70%;
  height: 20%;
  margin:5%;
  margin-top:0%;
  font-size:1em;
  line-height:2em;
}

.inside-in-cont{
  justify-content:center;
  text-align:left;
}

.inside-in-cont img{
  display: flex;
  justify-content: center;
  margin:auto;
  width:80%;
}

.inside-in-cont-header{
  font-size:1.25em;
  line-height:2.5em;
  font-weight:bold;
  border-bottom:2px solid #eee;
}

.header{
  font-family: fredoka one;
  font-size:3em;
  color:#424949;
  line-height:1.75em;
}

.img-header{
  background-image:url('@/assets/projectheaders/parallelimage.png');
  background-size:cover;
  width:90vw;
  height:60vh;
  top:0;
  left:0;
  box-shadow: 0px 10px 15px #eee; 
}

.back-btn{
  position:relative;
  border:1px solid #f49b90;
  display:inline-block;
  padding:2%;
  font-family:fredoka one;
  font-size:1em;
  border-radius:20px;
  color:#424949;
}


.back-btn:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}


@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }
  .header{
    font-size:2em;
  }

  .img-header{
    width:100vw;
    background-size:cover;
  }
}

</style>

<!-- References:

-- Skills Progress Bar
https://codepen.io/joshbivens/pen/mPRovV

-- Particlesjs
https://vincentgarreau.com/particles.js/

 -->

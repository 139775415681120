<template>
  <div class="container">
    <div class="animated fadeInLeft">
        <!-- HEADER -->
        <div class="img-header"></div>

        <!-- CONTENT -->
        <div class="window">
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">AniMatch</div> <br>

                    
                    <!-- Role Overview-->
                    <div class="inside-in-cont-header">Role Overview</div> <br>
                    <i>
                    <b>Project Time:</b>  July 2021 - August 2021 <br>
                    <b>Role:</b>  Full-stack Developer <br>
                    <b>Language:</b>  Java <br>
                    <b>Dependencies:</b>  Firebase, Glide, SwipeDeck <br><br>
                    </i>

                    I worked closely with a partner as a full-stack developer to create this Android application for a school project.
                    We both worked on the user interface design, as well as developed the application from the client-side 
                    to the server side. We have also designed the database to include account creation and information storage and retrieval.

                    <br><br>

                    <!-- Concept -->
                    <div class="inside-in-cont-header">Concept</div> <br>
                    AniMatch is a Tinder-inspired matching application designed to bring you your new favorite anime show! Quickly find your next match by swiping through the deck -- swipe right to add a show to your watchlist, and swipe left to remove this from your suggestions. Want more information on the show? Tap to read the synopsis!
                    
                    <br><br>
                    This is the landing page of the app. As can be seen, the application used instructions and marketing taglines to entice the user to register. Upon registration, potential users must input their username, email, password, and display name. In addition, they must be at least 13 years old to register successfully.
                    
                    <br><br>
                    <img src="@/assets/animatch/1.gif">

                    <br><br>
                    After successfully registering, the user is redirected to their main matching activity. The user is presented with various anime show recommendations with different genres and ratings. If the user is a minor, the deck filters out all anime shows rated R. Otherwise; the user is presented with every possible anime recommendation. The deck is only composed of 25 cards at a time. If the user runs out of cards, the deck is replenished again. More information is presented when the user taps on the card. Swiping right adds the show to their watchlist while swiping left removes this from the current deck. It is worth noting that swiping left does not permanently remove this from the deck. This show would reappear after some time, considering the possibility that a user would have changed their minds by then.

                    <br><br>
                    <img src="@/assets/animatch/2.gif">

                    <br><br>
                    The "matches" button redirects the user to their current watchlist (shows they have swiped right on). Tapping on a show reveals more information about the show and options to remove this show or move them to their finished shows. If a user completes a show, the application allows the user to rate the show. The "finished shows" button redirects the user to their list of finished shows. The layout is similar to the matchlist format, except for the block that indicates the user's rating.

                    <br><br>
                    <img src="@/assets/animatch/3.gif">

                    <br><br>
                    The "account" button redirects the user to their account information. In addition, this screen also allows the user to edit their account or log out. The application only allows users to edit their display name, username, email, and password. Users cannot edit their birthdays anymore to restrict the filtering feature for anime show ratings. Users may also delete their accounts if they wish to do so.
                    <br><br>
                    <img src="@/assets/animatch/4.gif">

                    <br><br>
                    It is worth noting that destroying the app does not destroy the user session. User sessions are only destroyed when they consciously log out of the application.

                    <br><br>
                    <img src="@/assets/animatch/5.gif">

                    <br><br>
                    The following clips show extra features regarding error handling when registering. As can be seen, no user can register with an email or username associated with an existing account. The register activity finishes as soon as registration is successful, wherein clicking back does not return you to the registration screen.

                    <br><br>
                    <img src="@/assets/animatch/6.gif">

                    <br><br>
                    <img src="@/assets/animatch/7.gif">
                    
                    <br><br>

                    <router-link to="/works">
                    <center><div class="back-btn">See more works</div></center>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'
import 'particles.js'

export default {
  name: 'ContentAniMatch',
  components: {
    FooterDiv
  },
  mounted(){
  },
  methods: {
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');


a{
  color:#2c3e50;
}

a:hover img{
  animation: tada 1s forwards;
}

video{
  width:100%;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  overflow:scroll;
  overflow-x:hidden;
  animation:fadeInLeft 0.05s;
}

.window{
  display:block;
  height: 100%;
  width: 100%;
  padding-top:5%;
  padding-left:10%;
  padding-right:10%;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}


.inside-cont{
  width: 70%;
  height: 20%;
  margin:5%;
  margin-top:0%;
  font-size:1em;
  line-height:2em;
}

.inside-in-cont{
  justify-content:center;
  text-align:left;
}

.inside-in-cont img{
  display: flex;
  justify-content: center;
  margin:auto;
  width:80%;
}

.inside-in-cont-header{
  font-size:1.25em;
  line-height:2.5em;
  font-weight:bold;
  border-bottom:2px solid #eee;
}

.header{
  font-family: fredoka one;
  font-size:3em;
  color:#424949;
  line-height:1.75em;
}

.img-header{
  background-image:url('@/assets/projectheaders/animatch.png');
  background-size:cover;
  width:90vw;
  height:60vh;
  top:0;
  left:0;
  box-shadow: 0px 10px 15px #eee; 
}

.back-btn{
  position:relative;
  border:1px solid #f49b90;
  display:inline-block;
  padding:2%;
  font-family:fredoka one;
  font-size:1em;
  border-radius:20px;
  color:#424949;
}


.back-btn:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}


@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }
  .header{
    font-size:2em;
  }

  .img-header{
    width:100vw;
    background-size:cover;
  }
}

</style>

<!-- References:

-- Skills Progress Bar
https://codepen.io/joshbivens/pen/mPRovV

-- Particlesjs
https://vincentgarreau.com/particles.js/

 -->

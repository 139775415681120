<template>
  <div class="container">

    <div class="animated fadeInLeft">
        <div class="window">
            
            <div class="inside-cont">
                <div class="header">My works</div>
                <!-- Filter Navigation-->
                <div class="filter-nav-wrap">
                  <div class="filter-nav-btn" id="filter-label" >Filter Works: </div>
                  <div class="filter-nav-btn" id="filter-all" @click="showAll()">All</div>
                  <div class="filter-nav-btn" id="filter-ui" @click="showUI()">UI/UX</div>
                  <div class="filter-nav-btn" id="filter-dev" @click="showDev()">Software Development</div>
                  <div class="filter-nav-btn" id="filter-ml" @click="showMl()">Data Analysis and Machine Learning</div>
                  <div class="filter-nav-btn" id="filter-algo" @click="showAlgo()">Algorithm Implementation</div>
                </div>

               <br>
                  
               <!-- WORK 1 (Angels Scent) -->
                <router-link to="/angelsscent">
                  <div class="sample-work dev ui" >
                    <img src="@/assets/workbanners/1.png" width="100" height="100">
                  </div>
                </router-link>

                
                <!-- WORK 2 (AniMatch)-->
                <router-link to="/animatch">
                <div class="sample-work dev ui">
                  <img src="@/assets/workbanners/2.png" width="100" height="100">
                </div>
                </router-link>

                <!-- WORK 3 (Parallel Image) -->
                <router-link to="/parallelimage">
                <div class="sample-work algo">
                  <img src="@/assets/workbanners/3.png" width="100" height="100">
                </div>
                </router-link>

                <!-- WORK 4 (Sentiment Analysis on IMDB)-->
                <a href="https://github.com/nicole-ocampo/stintsy-mp/blob/main/model.ipynb" target="_blank">
                <div class="sample-work ml">
                  <img src="@/assets/workbanners/4.png" width="100" height="100">
                </div>
                </a>

                <!-- WORK 5 (Security Svcs) -->
                <div class="sample-work dev algo">
                  <img src="@/assets/workbanners/8.png" width="100" height="100">
                </div>

                <!-- WORK 6 (YouLearn)-->
                <div class="sample-work dev ui">
                  <img src="@/assets/workbanners/7.png" width="100" height="100">
                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'

export default {
  name: 'ContentWorksPage',
  components: {
    FooterDiv
  },
  methods:{
    showAll(){
      var uiList = document.getElementsByClassName("sample-work")
      var allBtn = document.getElementById("filter-all");
      var uiBtn = document.getElementById("filter-ui");
      var devBtn = document.getElementById("filter-dev");
      var mlBtn = document.getElementById("filter-ml");
      var algoBtn = document.getElementById("filter-algo");


      for(var i = 0; i < uiList.length; i++)
        uiList[i].style.display="block";          
        
      // Make UI-btn active
      allBtn.style.background="#FFC4BD";
      allBtn.style.border="2px solid #FFC4BD";
      allBtn.style.color="#fff";

      // Make other btns regular
      uiBtn.style.background="#fff";
      uiBtn.style.border="2px solid #e5e8e8";
      uiBtn.style.color="#2c3e50";

      devBtn.style.background="#fff";
      devBtn.style.border="2px solid #e5e8e8";
      devBtn.style.color="#2c3e50";

      mlBtn.style.background="#fff";
      mlBtn.style.border="2px solid #e5e8e8";
      mlBtn.style.color="#2c3e50";

      algoBtn.style.background="#fff";
      algoBtn.style.border="2px solid #e5e8e8";
      algoBtn.style.color="#2c3e50";
    },

    showUI(){
      this.showAll();

      var uiList = document.getElementsByClassName("sample-work")
      var allBtn = document.getElementById("filter-all");
      var uiBtn = document.getElementById("filter-ui");
      var devBtn = document.getElementById("filter-dev");
      var mlBtn = document.getElementById("filter-ml");
      var algoBtn = document.getElementById("filter-algo");

      // Check for UI tags
      for(var i = 0; i < uiList.length; i++) {
        var className = uiList[i].className.split(" ");
        var isUi = false;
        
        for (var j=1; j<=className.length; j++){
          if (className[j] == "ui"){
            isUi = true;
            break;
          }
        }

        if(!isUi)
          uiList[i].style.display="none";
      }

      // Make UI-btn active
      uiBtn.style.background="#FFC4BD";
      uiBtn.style.border="2px solid #FFC4BD";
      uiBtn.style.color="#fff";

      // Make other btns regular
      allBtn.style.background="#fff";
      allBtn.style.border="2px solid #e5e8e8";
      allBtn.style.color="#2c3e50";

      devBtn.style.background="#fff";
      devBtn.style.border="2px solid #e5e8e8";
      devBtn.style.color="#2c3e50";

      mlBtn.style.background="#fff";
      mlBtn.style.border="2px solid #e5e8e8";
      mlBtn.style.color="#2c3e50";

      algoBtn.style.background="#fff";
      algoBtn.style.border="2px solid #e5e8e8";
      algoBtn.style.color="#2c3e50";
    },

    showDev(){
        this.showAll();

        var devList = document.getElementsByClassName("sample-work")
        var allBtn = document.getElementById("filter-all");
        var uiBtn = document.getElementById("filter-ui");
        var devBtn = document.getElementById("filter-dev");
        var mlBtn = document.getElementById("filter-ml");
        var algoBtn = document.getElementById("filter-algo");

        // Check for dev tags
        for(var i = 0; i < devList.length; i++) {
          var className = devList[i].className.split(" ");
          var isDev = false;
          
          for (var j=1; j<=className.length; j++){
            if (className[j] == "dev"){
              isDev = true;
              break;
            }
          }

          if(!isDev)
            devList[i].style.display="none";
        }

        // Make dev-btn active
        devBtn.style.background="#FFC4BD";
        devBtn.style.border="2px solid #FFC4BD";
        devBtn.style.color="#fff";

        // Make other btns regular
        allBtn.style.background="#fff";
        allBtn.style.border="2px solid #e5e8e8";
        allBtn.style.color="#2c3e50";

        uiBtn.style.background="#fff";
        uiBtn.style.border="2px solid #e5e8e8";
        uiBtn.style.color="#2c3e50";

        mlBtn.style.background="#fff";
        mlBtn.style.border="2px solid #e5e8e8";
        mlBtn.style.color="#2c3e50";

        algoBtn.style.background="#fff";
        algoBtn.style.border="2px solid #e5e8e8";
        algoBtn.style.color="#2c3e50";
    },

    showMl(){
        this.showAll();

        var mlList = document.getElementsByClassName("sample-work")
        var allBtn = document.getElementById("filter-all");
        var uiBtn = document.getElementById("filter-ui");
        var devBtn = document.getElementById("filter-dev");
        var mlBtn = document.getElementById("filter-ml");
        var algoBtn = document.getElementById("filter-algo");

        // Check for ml tags
        for(var i = 0; i < mlList.length; i++) {
          var className = mlList[i].className.split(" ");
          var isMl = false;
          
          for (var j=1; j<=className.length; j++){
            if (className[j] == "ml"){
              isMl = true;
              break;
            }
          }

          if(!isMl)
            mlList[i].style.display="none";
        }

        // Make ML-btn active
        mlBtn.style.background="#FFC4BD";
        mlBtn.style.border="2px solid #FFC4BD";
        mlBtn.style.color="#fff";

        // Make other btns regular
        allBtn.style.background="#fff";
        allBtn.style.border="2px solid #e5e8e8";
        allBtn.style.color="#2c3e50";

        uiBtn.style.background="#fff";
        uiBtn.style.border="2px solid #e5e8e8";
        uiBtn.style.color="#2c3e50";

        devBtn.style.background="#fff";
        devBtn.style.border="2px solid #e5e8e8";
        devBtn.style.color="#2c3e50";

        algoBtn.style.background="#fff";
        algoBtn.style.border="2px solid #e5e8e8";
        algoBtn.style.color="#2c3e50";
    },

    showAlgo(){
        this.showAll();
      
        var algoList = document.getElementsByClassName("sample-work")
        var allBtn = document.getElementById("filter-all");
        var uiBtn = document.getElementById("filter-ui");
        var devBtn = document.getElementById("filter-dev");
        var mlBtn = document.getElementById("filter-ml");
        var algoBtn = document.getElementById("filter-algo");

        // Check for ml tags
        for(var i = 0; i < algoList.length; i++) {
          var className = algoList[i].className.split(" ");
          var isAlgo = false;
          
          for (var j=1; j<=className.length; j++){
            if (className[j] == "algo"){
              isAlgo = true;
              break;
            }
          }

          if(!isAlgo)
            algoList[i].style.display="none";
        }

        // Make algo-btn active
        algoBtn.style.background="#FFC4BD";
        algoBtn.style.border="2px solid #FFC4BD";
        algoBtn.style.color="#fff";

        // Make other btns regular
        allBtn.style.background="#fff";
        allBtn.style.border="2px solid #e5e8e8";
        allBtn.style.color="#2c3e50";

        uiBtn.style.background="#fff";
        uiBtn.style.border="2px solid #e5e8e8";
        uiBtn.style.color="#2c3e50";

        devBtn.style.background="#fff";
        devBtn.style.border="2px solid #e5e8e8";
        devBtn.style.color="#2c3e50";

        mlBtn.style.background="#fff";
        mlBtn.style.border="2px solid #e5e8e8";
        mlBtn.style.color="#2c3e50";
    }
  },
  mounted(){
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');

a{
  color:#2c3e50;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  background-color: white;
  overflow:scroll;
  overflow-x:hidden;
}

.window{
  display:block;
  height: 100%;
  width: 100%;
  padding-top:10%;
  padding-left:10%;
  padding-right:10%;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

.inside-cont{
  width: 70%;
  height: max-content;
  padding-left:5%;
  padding-right:5%;
  padding-bottom:5%;
  font-size:1em;
}

.inside-in-cont{
  text-align:left;
}

.header{
  text-align:left;
  font-family: fredoka one;
  font-size:3em;
  color:#424949;
  line-height:2.5em;
}

.filter-nav-wrap{
  width:100%;
  display:flex;
  flex-direction: row;
}

.filter-nav-btn{
  width:max-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin:0.5%;
  padding-left:1%;
  padding-right:1%;
  border-radius:20px;
  border:2px solid #e5e8e8;
  color:#2c3e50;
}

.filter-nav-btn:hover{
  background:#FFC4BD !important;
  border:2px solid #FFC4BD !important;
  color:#fff !important;
  cursor: pointer;
}

#filter-label{
  border:0px !important;
  overflow:visible !important;
}

#filter-label:hover{
  background:none !important;
  color:#2c3e50 !important;
  cursor:default;
}

#filter-all, #filter-ui{
  overflow:visible;
}

.sample-work{
  width:100%;
  height:20vw;
  margin-bottom:3%;
  overflow:hidden;
  position:relative;
  border-radius:20px;
  box-shadow: 10px 10px 15px #eee; 
}

.sample-work:hover{
  cursor:pointer;
  animation: pulse 1s;
}



.sample-work img {
  object-fit:cover;
  width:inherit;
  height:inherit;  
}

@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }

  .header{
    font-size:2em;
  }

  .sample-work{
    height:20vh;
  }

}

</style>

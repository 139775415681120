import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import AboutPage from '../views/AboutPage.vue'
import WorksPage from '../views/WorksPage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import ContactPage from '../views/ContactPage.vue'
import AngelsScentPage from '../views/AngelsScent.vue'
import AniMatchPage from '../views/AniMatch.vue'
import ParallelImagePage from '../views/ParallelImage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/works',
    name: 'works',
    component: WorksPage
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/angelsscent',
    name: 'angelsscent',
    component: AngelsScentPage
  },
  {
    path: '/animatch',
    name: 'animatch',
    component: AniMatchPage
  },
  {
    path: '/parallelimage',
    name: 'parallelimage',
    component: ParallelImagePage
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'NotFoundPage',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

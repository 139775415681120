<template>
  <div id="container">
    <ContentWorksPage/>
    <NavBar/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import ContentWorksPage from '@/components/ContentWorksPage.vue'

export default {
  name: 'WorksPage',
  components: {
    NavBar,
    ContentWorksPage
}
}
</script>

<style scoped>
#container{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
</style>

<template>
    <div class="container">
      
      <!-- BUBBLE BG -->
      <div class="bubbles bubble-1"></div>
      <div class="bubbles bubble-5"></div>
      <div class="bubbles bubble-2"></div>
      <div class="bubbles bubble-6"></div>
      <div class="bubbles bubble-3"></div>
      <div class="bubbles bubble-7"></div>
      <div class="bubbles bubble-4"></div>
      <div class="bubbles bubble-8"></div>
  
      <div id="contain-all">
        <div class="window" id="contactbox"> 
          <div id="wrap">
            <div id="window3-desc">
              <div class="window3-text">
                <div class="about-header">Let's get in touch!</div>
                 I am currently open for freelance and full-time positions. Let's talk! 
  
                 <br><br><br>
                  <div id="window3-form">
                    <form ref="form" @submit.prevent="sendEmail" id="contactform">
  
                      <div class="form-row1-wrapper">
                        <label for="sname">Name:</label> <br>
                        <input type="text" name="sname" id="sname"> 
                      </div>
  
                      <div class="form-row1-wrapper">
                        <label for="snumber">Mobile Number:</label> <br>
                        <input type="text" name="snumber" id="snumber">
                      </div>
  
                      <div class="form-row2-wrapper">
                        <label for="semail">Email Address:</label> <br>
                        <input type="text" name="semail" id="semail">
                      </div>
  
                      <div class="form-row2-wrapper">
                        <label for="ssubject">Subject:</label> <br>
                        <input type="text" name="ssubject" id="ssubject">
                      </div>
  
                      <div class="form-row2-wrapper">
                        <label for="smessage">Message:</label> <br>
                        <textarea name="smessage" id="smessage"></textarea>
                      </div>
  
                      <br><br>
                      <div class="form-submit-wrapper">
                        <input type="submit" value="Submit">
                      </div>
                    </form>
                  </div>
                
                  <br><br>
                </div>
            </div>
          </div>
        </div>
        
      </div>
<!-- FOOTER -->
<FooterDiv/>
    </div>
  </template>
  
  
  <script>
  import FooterDiv from '@/components/FooterDiv.vue'
  import emailjs from '@emailjs/browser'
  
  export default {
    name: 'ContactCompPage',
    components: {
      FooterDiv
    },
    methods:{
      sendEmail() {
      emailjs.sendForm('service_04msohb', 'template_sgfwy1r', this.$refs.form, 'm7Sa0JkB8r8JwP_c4')
        .then((result) => {
            console.log('SUCCESS!', result.text);
            window.alert("Message successfully sent!");
            document.getElementById("contactform").reset();
        }, (error) => {
            console.log('FAILED...', error.text);
            window.alert("ERROR: Kindly submit your message again.");
        });
      }
    },
    mounted(){
    }
  }
  </script>
  
  <style scoped>
  
  @import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');
  
  html {
    scroll-behavior: smooth;
  }
  
  a{
    color:#2c3e50;
  }
  
  .container{
    font-family:poppins;
    height: 100%;
    width: 90vw;
    top: 0;
    left: 0;
    background-color: white;
    overflow:scroll;
    overflow-x:hidden;
  }
  
  #contain-all{
    display:block;
    height: auto;
    width: 90vw;
    top: 0;
    left: 0;
    animation: fadeInLeft 1.5s;
  }
  
  .window{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  
  ::-webkit-scrollbar {
    width: 0;  
    background: transparent; 
  }
  
  
  highlight{
    background-color: #f49b90;
    color: #fff;
    padding-left:1%;
    padding-right:1%;
  }
  
  
  #wrap{
    width:90vw;
    height:100vh;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
  }
    
  .about-header{
    font-family: fredoka one;
    font-size:2em;
    color:#424949;
    line-height:2.5em;
  }
  
  #window3-desc{
    width:60%;
    height:60%;
    overflow:scroll;
    padding:3%;
    border: 5px dotted #FFC4BD;
    box-shadow: 10px 10px 15px #eee; 
  }
  
  .window3-text{
    width:100%;
    text-align: left;
    font-family:'poppins';
    font-size:1em;
    color:#424949;
  }
  
  
  #window3-form{
    width:100%;
    height:100%;
  }
  
  input, textarea{
    font-family:poppins;
    font-size:1em;
    width:100%;
    padding:1%;
    border-radius:2%;
    border: 1px solid #eee;
  }
  
  .form-row1-wrapper{
    padding-right:5%;
    display:inline-block;
    text-align:left;
    width:45%;
  }
  .form-row2-wrapper{
    display:inline-block;
    text-align:left;
    width:94%;
  }
  .form-submit-wrapper{
    display:inline-block;
    text-align:left;
    width:96%;
  }
  
  #smessage{
    height:10vh;
  }
  
  input[type=submit]{
    display:inline-block;
    padding:1%;
    font-family:fredoka one;
    font-size:1em;
    border-radius:10px;
    background:#fff;
    color: #424949;
    border: 1px solid #FFC4BD;
  }
  
  input[type=submit]:hover{
    color:#fff;
    background: #f49b90;
    cursor:pointer;
    animation: pulse .5s;
  }
  

  /* For mobile */
  @media (max-width: 670px) {
    .container{
      width:100vw;
    }
  
    #wrap{
      margin-top:15%;
      padding-top:0%;
      flex-direction: column;
    }
  
    #text-wrapper{
      width:90vw;
      margin-left:5%;
    }
  
    #window3-desc{
      width:80vw;
      height:auto;
      border-radius:20px;
      margin-left:5vw;
    }
  
    .about-header{
      font-size:1.5em;
    }
  
    .window3-text{
      font-size:0.8em;
    }
  
    .text{
      font-size:2em;
    }
  
  }
  
  
  /* Bubbles */
  .bubbles {
    position: absolute;
    bottom: 0;
    background-color: #f49b90;
    border-radius: 50%;
    opacity: .50;
    width: 20px;
    height: 20px;
    animation: up 4s infinite;
  }
  
  .bubble-2 {
    left: 350px;
    animation: up 2s infinite;
  }
  
  .bubble-3 {
    left: 750px;
    opacity: .30;
    animation: up 5s infinite;
  }
  
  .bubble-4 {
    left: 1150px;
    animation: up 6s infinite;
  }
  
  .bubble-5 {
    left: 50px;
    animation: up 5s infinite;
  }
  
  .bubble-6 {
    left: 300px;
    opacity: .30;
    animation: up 3s infinite;
  }
  
  .bubble-7 {
    left: 670px;
    animation: up 4s infinite;
  }
  
  .bubble-8 {
    left: 1050px;
    opacity: .30;
    animation: up 3s infinite;
  }
  
  
  @keyframes up-scroll { 
      0% {
      transform: translateY(0px);
    }
      to {
      transform: translateY(-100%)
    }
  }
  
  @keyframes down-scroll { 
      0% {
      transform: translateY(-100%);
    }
      to {
      transform: translateY(0%)
    }
  }
  
  
  @keyframes up {
    100% {
      transform: translateY(-800px);
    }
  }
  
  @keyframes shadows {
    0% {
      text-shadow: none;
    }
    10% {
      text-shadow: 3px 3px 0 #f49b90;
    }
    20% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d;
    }
    30% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
    }
    40% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
        12px 12px 0  #ee6352;
    }
    50% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
        12px 12px 0  #ee6352;
    }
    60% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d, 9px 9px  #f07a6a,
        12px 12px 0  #ee6352;
    }
    70% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d, 9px 9px  #f07a6a;
    }
    80% {
      text-shadow: 3px 3px 0 #f49b90,
        6px 6px 0 #f28b7d;
    }
    90% {
      text-shadow: 3px 3px 0 #f49b90;
    }
    100% {
      text-shadow: none;
    }
  }
  
  @keyframes move {
    0% {
      transform: translate(0px, 0px);
    }
    40% {
      transform: translate(-12px, -12px);
    }
    50% {
      transform: translate(-12px, -12px);
    }
    60% {
      transform: translate(-12px, -12px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
  
  </style>
  
  <!-- References:
  
  -- Growing Text Animation
  https://codepen.io/alvarotrigo/pen/dyzxgOO
  
  -- Bubbles Animation
  https://codepen.io/jonitrythall/pen/DBeeqJ
  
  -- Text Scramble
  https://codepen.io/soulwire/pen/mEMPrK
  
   -->
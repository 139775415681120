<template>
  <div class="container">
    <div class="animated fadeInLeft">
        <div class="window">
            <!-- Work Header -->
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">Hello! </div>

                    <div class="profile"><img src="@/assets/profile.png" alt="Profile Photo"></div>
                    <!-- I have great passion for integrating ideas stemming from 
                        careful research and analysis into a software product to create a holistic and immersive user
                        experience.  -->
                    I'm Nicole Ocampo, a software engineer based in the Philippines. I finished 
                    my undergraduate degree in Computer Science with a specialization in Software Technology in 2023. 
                    I am currently working as an Associate Applications Engineer in the Philippines.

                    <br><br>
                    I have chosen software engineering as a career path primarily because user interfaces have 
                    always piqued my interest. The overall process of research, analysis, and development, 
                    especially in the context of user experience, is something that I deeply enjoy. 

                    Aside from building software, I also enjoy visualizing and analyzing data. Extracting 
                    insights from existing observations excites and inspires me to create a compelling story 
                    about how and why things work a certain way.

                    <br><br>
                    I hope to be able to contribute to creating a holistic digital experience for everyone 
                    through extensive research, the construction of intuitive designs, and data analysis. 
                    I am always excited about learning new things and am more than happy to share this experience 
                    with you through our collaboration! :)

                    <br><br>
                </div>
            </div>

      
            <!-- Skill Section -->
            <br><br>
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">Skills</div>

                    <div class="out-skill-wrap">
                      <!-- Row 1 Wrap -->
                      <div class="skills-row-wrap">

                        <!-- Skill Section 1-->
                        <div class="skills-content-wrap">
                            <div class="skill-header">UI/UX Research and Design</div>
                              <ul>
                                <li>Needfinding Study</li>
                                <li>Problem Framing</li>
                                <li>Empathy Mapping</li>
                                <li>User Persona Creation</li>
                                <li>High-fidelity Prototyping</li>
                                <li>Wireframing</li>
                                <li>Usability Testing</li>
                                <li>Web Design</li>
                                <li>Mobile Design</li>
                                <li>Responsive Design</li>
                              </ul>
                        </div>

                        <!-- Skill Section 2-->
                        <div class="skills-content-wrap">
                            <div class="skill-header">Full stack development</div>
                              <ul>
                                <li>Search Engine Optimization</li>
                                <li>HTML5, CSS, Bootstrap</li>
                                <li>DOM Manipulation, JavaScript, jQuery</li>
                                <li>NodeJS, Java, Python</li>
                                <li>VueJS, ReactJS</li>
                                <li>MySQL, MongoDB, Firebase</li>
                                <li>Netlify, Heroku</li>
                                <li>Android Development</li>
                                <li>GitHub</li>
                                <li>DevOps, Scrum</li>
                              </ul>
                        </div>
                      </div>
                      
                      <!-- Row 2 Wrap -->
                      <div class="skills-row-wrap">
                        <!-- Skill Section 3-->
                        <div class="skills-content-wrap">
                            <div class="skill-header">Data Analytics</div>
                              <ul>
                                <li>Exploratory Data Analysis</li>
                                <li>Data Wrangling</li>
                                <li>Data Cleaning</li>
                                <li>Data Visualization</li>
                                <li>Python, Microsoft Excel</li>
                                <li>Jupyter Lab</li>
                                <li>NumPy, SciPy</li>
                                <li>matplotlib, seaborn</li>
                                <li>pandas</li>
                                <li>PyTorch</li>
                              </ul>
                        </div>

                        <!-- Skill Section 4-->
                        <div class="skills-content-wrap">
                            <div class="skill-header">Machine Learning</div>
                              <ul>
                                <li>Linear algebra</li>
                                <li>Probability and Statistics</li>
                                <li>Calculus</li>
                                <li>Algorithms and Optimization</li>
                                <li>Parallel Computing</li>
                                <li>Data Modeling</li>
                                <li>Classification and Regression</li>
                                <li>Neural Networks</li>
                                <li>Python, Jupyter Lab</li>
                                <li>Scikit-learn, TensorFlow</li>
                              </ul>
                        </div>
                      </div>
                    <!-- Out Skill Wrap End tag -->
                    </div>
                </div>
            </div>


            <!-- Tools -->
            <div class="inside-cont">
              <div class="inside-in-cont">
                  <div class="header">Toolkit</div>
                  This is the toolkit I am currently using from ideation to development. While I use these tools as a 
                  preference, I am always open to learning new approaches and strategies to streamline my process.

                  <br><br>

                  <table>
                    <tr>
                        <th>
                            <a href="https://www.notion.so" title="Notion" target="_blank">
                              <img src="@/assets/toolkit/notion.png" width="50" height="40" alt="Notion">
                            </a>
                        </th>

                        <th>
                            <a href="https://miro.com" title="Miro" target="_blank">
                              <img src="@/assets/toolkit/miro.png" width="50" height="40" alt="Miro">
                            </a>
                        </th>

                        <th>
                            <a href="https://www.figma.com" title="Figma" target="_blank">
                              <img src="@/assets/toolkit/figma.png" width="50" height="40" alt="Figma">
                            </a>
                        </th>

                        <th>
                            <a href="https://www.adobe.com/products/xd.html" title="Adobe XD" target="_blank">
                              <img src="@/assets/toolkit/xd.png" width="50" height="40" alt="Adobe XD">
                            </a>
                        </th>

                        <th>
                            <a href="https://www.adobe.com/products/photoshop.html" title="Adobe Photoshop" target="_blank">
                              <img src="@/assets/toolkit/photoshop.png" width="50" height="40" alt="Adobe Photoshop">
                            </a>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <a href="https://www.clipstudio.net/en/" title="Clip Studio Paint" target="_blank">
                              <img src="@/assets/toolkit/csp.png" width="80" height="70" alt="Clip Studio Paint">
                            </a>
                        </th>

                        <th>
                            <a href="https://quant-ux.com/#/" title="Quant-Ux" target="_blank">
                              <img src="@/assets/toolkit/quantux.png" width="50" height="40" alt="Quant-Ux">
                            </a>
                        </th>

                        <th>
                            <a href="https://webflow.com" title="Webflow" target="_blank">
                              <img src="@/assets/toolkit/webflow.png" width="50" height="20" alt="Webflow">
                            </a>
                        </th>

                        <th>
                            <a href="https://vuejs.org" title="VueJs" target="_blank">
                              <img src="@/assets/toolkit/vue.png" width="50" height="40" alt="VueJs">
                            </a>
                        </th>

                        <th>
                            <a href="https://code.visualstudio.com" title="VSCode" target="_blank">
                              <img src="@/assets/toolkit/vscode.png" width="50" height="40" alt="VSCode">
                            </a>
                        </th>
                      
                    </tr>
                  </table>
              
              
              </div>
            </div>




        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'
import 'particles.js'

export default {
  name: 'ContentAboutPage',
  components: {
    FooterDiv
  },
  data: () => ({ publicPath: process.env.BASE_URL }),
  mounted(){
  },
  methods: {
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');


a{
  color:#2c3e50;
}

a:hover img{
  animation: tada 1s forwards;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  overflow:scroll;
  overflow-x:hidden;
  animation:fadeInLeft 0.05s;
}

.window{
  display:block;
  height: 100%;
  width: 100%;
  padding-top:10%;
  padding-left:10%;
  padding-right:10%;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

.profile{
  width:20vw;
}

.profile img{
  width:90%;
  height:90%;
  margin-right: 10%;
  margin-bottom:5%;
  float:left;
}


.inside-cont{
  width: 70%;
  height: 20%;
  margin:5%;
  margin-top:0%;
  font-size:1em;
}

.inside-in-cont{
  justify-content:center;
  text-align:left;
}

.header{
  font-family: fredoka one;
  font-size:3em;
  color:#424949;
  line-height:1.75em;
}

.cv-button img{
  float:left;
  margin-top:12%;
}

.cv-button a{
  color:#f49b90;
  text-decoration:none;
}


.cv-button{
  float:right;
  width:max-content;
  height:max-content;
  display:flex;
  justify-content: right;
  font-family:fredoka one;
  font-size:0.4em;
  color:#f49b90;
  border-radius:20px;
}

.cv-button:hover{
  cursor:pointer;
  animation: tada 1s;
}


.skills-row-wrap{
  display:flex;
  flex-direction:row;
  width:100%;
}

.skills-content-wrap{
  display:inline-block;
  width:50vw;
  margin:1%;
  padding:3%;
  border-radius:20px;
  border:1px solid #FFC4BD;
}

.skill-header{
  text-align:center;
  font-size:1.25em;
  margin:auto;
  padding:2%;
  font-family:fredoka one;
}

.out-skill-wrap{
  display:flex;
  flex-direction: column;
}

table{
  width:100%;
}

table tr{
  width:100%;
}

table th{
  margin:auto;
  width:20%;
  padding:3%;
}

@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }

  .header{
    font-size:2em;
  }

  .cv-button img{
    margin-top:5%;
    width: 20%;
    height:60%;
  }

  .cv-button{
    font-size:0.55em;
    width:45%;
  }

  .skills-row-wrap{
    flex-direction: column;
  }

  .skills-content-wrap{
    margin:auto;
    margin-bottom:3%;
    width:80vw;
  }

  img{
    width: 70%;
  }

  .profile{
    width:50vw;
  }

  .profile img{
    width:80%;
    height: 80%;
  }

}

</style>

<!-- References:

-- Skills Progress Bar
https://codepen.io/joshbivens/pen/mPRovV

-- Particlesjs
https://vincentgarreau.com/particles.js/

 -->

<template>
  <div class="container">
    <div class="animated fadeInLeft">
        <!-- HEADER -->
        <div class="img-header"></div>

        <!-- CONTENT -->
        <div class="window">
            <div class="inside-cont">
                <div class="inside-in-cont">
                    <div class="header">Angel's Scent</div> <br>

                    <!-- Client Background -->
                    <div class="inside-in-cont-header"> Client Background </div> <br>
                    Angel’s Scent PH is a small-scale business, founded in 2018, aiming to deliver handcrafted perfumes to men and women. It was founded by Ms. Ardenio, and is currently operating in Makati City, Philippines.
                    
                    The company's central vision as a business is to incite consumer confidence through the power of 
                    curated scents. With its target audience of men and women belonging to the middle class, 
                    the brand consistently aims to provide reasonably-priced, quality fragrances. In addition, the business also 
                    aims to provide extra business opportunities to resellers by acting as a supplier of curated perfumes 
                    available for distribution.

                    <br><br>

                    To market its products, the company utilizes two platforms to communicate with its potential 
                    customers: Facebook and Carousell. Most of the business processes are done on the respective messaging 
                    channels of the two platforms. Such functions include responding to inquiries, placing orders, 
                    payment verification, and shipment information.

                    <br><br>
                    <img src="@/assets/angelsscent/1.png">

                    <br><br><br>

                    <!-- Role Overview-->
                    <div class="inside-in-cont-header">Role Overview</div> <br>
                    <i>
                    <b>Project Time:</b>  July 2021 - September 2021 <br>
                    <b>Role:</b>  Product Owner <br><br>
                    </i>

                    Our team followed the <b>Scrum framework</b> for project management. 
                    I was appointed to be the Product Owner, which is the one accountable for creating the product vision,
                    maximizing the product's value, and managing the team's workflow. Throughout the project timeline, my responsibilities
                    are listed as follows:

                    <ul>
                      <li>Interact with the client</li>
                      <li>Create the Software Requirements Specifications</li>
                      <li>Create the product backlog and sprint plans</li>
                      <li>Spearhead every scrum meeting</li>
                      <li>Ensure that the team is in line with the planned schedule</li>
                      <li>Demonstrate the software solution to the client</li>
                      <li>Sign off the software solution to the client</li>
                    </ul>

                    
                    <br><br>

                    <!-- Dissecting the problem -->
                    <div class="inside-in-cont-header">Dissecting the problem</div> <br>
                    Curating a software solution from scratch is more challenging than one may think. The product 
                    vision must be able to contain the business processes and technicalities, all while simultaneously 
                    catering to the user experience. With this, I began interviewing the client about their business 
                    processes to gain an idea of the possible functionalities of our proposed software solution. The business model can
                    be visualized with the following diagram.

                    <br><br>
                    <img src="@/assets/angelsscent/2.png">
                    
                    <br><br>

                    As mentioned, Angel’s Scent PH markets its product through two major platforms: Facebook and Carousell. 
                    After creating a deal with its consumers, the company verifies the buyer’s payment and ships the 
                    products accordingly. Once the consumer receives their order, they are encouraged to write a review 
                    about the product via the respective review channels of each platform.

                    <br><br>

                    From the diagram itself, the current business process is already too complex. Using two 
                    different platforms when interacting with potential clients poses a disadvantage to the business, 
                    which may lead to a loss in potential sales. Moreover, the absence of a unified platform prevents 
                    the company from highlighting its brand identity due to the fierce competition 
                    in established selling platforms. I summarized these findings in the table as follows.

                    <br><br>
                    <img src="@/assets/angelsscent/3.png">

                    <br><br>

                    <!-- Proposed Software Solution -->
                    <div class="inside-in-cont-header">Proposed Software Solution</div> <br>
                    Because of the problems presented, I suggested a simplified business process so that there is 
                    only one platform for all the operations. Instead of having the seller go back and forth between 
                    two platforms while simultaneously responding to different inquiries, the proposed software solution 
                    aims to bridge this gap between these processes. 

                    <br><br>
                    <img src="@/assets/angelsscent/4.png">

                    <br><br>

                    <!-- Understanding the Users -->
                    <!-- Prospective users, needs, wants -->
                    <div class="inside-in-cont-header">Understanding the Users</div> <br>
                    From the interview, I discovered that there are two main categories of users in the business process. 
                    Each role description and its tasks are listed in the table below. This summary anchors the primary functions of the software application. 

                    <br><br>
                    <img src="@/assets/angelsscent/5.png">


                    <br><br>

                    <!-- Development of the Product Backlog -->
                    <!-- Functionalities, features -->
                    <div class="inside-in-cont-header">Development of the Product Backlog</div> <br>
                    A user story narrates possible scenarios from a role's (owner or buyer) perspective when interacting with 
                    the software. The business processes presented earlier is framed in the context of a user story to lessen
                    the technicalities, and allow each feature to be easily understood by each team member.

                    After dissecting the problem, I created the <b>Product Backlog</b> containing <b>11 user stories</b> 
                    to have a detailed list of what needs to be accomplished.
                    
                    <br><br>
                    <img src="@/assets/angelsscent/6.png">
                    <br>

                    Each user story contains the following features:
                    <ul>
                      <li><b>Estimate or Story Points -- </b>  A numerical figure that denotes the importance of each feature. Adding all story points must equate to 100.</li>
                      <li><b>Priority -- </b>  Denotes the urgency of when the feature needs to be accomplished. This also considers the design, development, and quality testing aspects of the feature.</li>
                      <li><b>Taken by -- </b>  The team members in charge of developing the feature</li>
                      <li><b>Pre-condition -- </b> The prerequisite needed to implement the feature</li>
                      <li><b>Scenario -- </b>  Describes the current state of the application when this feature is applied </li>
                      <li><b>Post-condition -- </b>  The intended effect after the feature is used</li>
                      <li><b>Acceptance Criteria -- </b>  Criteria for marking the user story as resolved</li>
                      <li><b>Subtasks -- </b>  Detailed outline of tasks to be performed </li>
                    
                    </ul>
                    <br>

                    The product backlog has allowed the team to achieve transparency during development. Since the milestones
                    are listed in an organized manner, our team was also able to communicate effectively concerning the
                    workload division.

                    <br><br>

                    <!-- Sprint Planning -->
                    <!-- Group chats, trello -->
                    <div class="inside-in-cont-header">Sprint Planning</div> <br>
                    Our team has worked closely throughout three sprints. Each sprint focuses on accomplishing a 
                    number of user stories, from design and development to quality testing. To plan these sprints, 
                    I prioritized each user story according to the estimated time it would take to accomplish and the 
                    complexity of each task. For example, user stories involving back-end functionalities would require 
                    more time and should be finished earlier. On the other hand, user stories dealing with only front-end 
                    functionalities are less complex and should be okay to be scheduled during the last sprint.
                    
                    <br><br>
                    <img src="@/assets/angelsscent/7.png">
                    <br>
                    Our overall progress is tracked using a shared Trello board. We had bi-weekly meetings 
                    to discuss significant updates such as database designs and feature demonstrations. For minor updates, 
                    we used our team group chat to notify each team member. 

                    <br><br>
                    <img src="@/assets/angelsscent/8.png">
                    <br><br>


                    <!-- Demo -->
                    <div class="inside-in-cont-header">Demo</div> <br>
                    Our team was able to complete all user stories on time successfully. Our Quality Assurance Team 
                    thoroughly tested each feature to ensure no defects were present upon sign-off. The client signed 
                    off the application on September 17, 2021. 

                    <br><br>
                    <video controls="controls">
                      <source src="@/assets/angelsscent/demo.mp4" type="video/mp4">
                    </video>
                    <br><br>

                    <router-link to="/works">
                    <center><div class="back-btn">See more works</div></center>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <FooterDiv/>
  </div>
</template>


<script>
import FooterDiv from '@/components/FooterDiv.vue'
import 'particles.js'

export default {
  name: 'ContentAngelsScent',
  components: {
    FooterDiv
  },
  mounted(){
  },
  methods: {
  }
}
</script>

<style scoped>

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');


a{
  color:#2c3e50;
}

a:hover img{
  animation: tada 1s forwards;
}

video{
  width:100%;
}

.container{
  font-family:poppins;
  height: 100%;
  width: 90vw;
  top: 0;
  left: 0;
  overflow:scroll;
  overflow-x:hidden;
  animation:fadeInLeft 0.05s;
}

.window{
  display:block;
  height: 100%;
  width: 100%;
  padding-top:5%;
  padding-left:10%;
  padding-right:10%;
  top: 0;
  left: 0;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}


.inside-cont{
  width: 70%;
  height: 20%;
  margin:5%;
  margin-top:0%;
  font-size:1em;
  line-height:2em;
}

.inside-in-cont{
  justify-content:center;
  text-align:left;
}

.inside-in-cont img{
  display: flex;
  justify-content: center;
  margin:auto;
  width:80%;
}

.inside-in-cont-header{
  font-size:1.25em;
  line-height:2.5em;
  font-weight:bold;
  border-bottom:2px solid #eee;
}

.header{
  font-family: fredoka one;
  font-size:3em;
  color:#424949;
  line-height:1.75em;
}

.img-header{
  background-image:url('@/assets/projectheaders/angelsscent.png');
  background-size:cover;
  width:90vw;
  height:60vh;
  top:0;
  left:0;
  box-shadow: 0px 10px 15px #eee; 
}

.back-btn{
  position:relative;
  border:1px solid #f49b90;
  display:inline-block;
  padding:2%;
  font-family:fredoka one;
  font-size:1em;
  border-radius:20px;
  color:#424949;
}


.back-btn:hover{
  background-color: #f49b90;
  color:#fff;
  cursor:pointer;
  animation: rubberBand 1.5s;
}


@media (max-width: 670px) {
  .container{
    width:100vw;
  }

  .inside-cont{
    width:90%;
    font-size:0.9em;
  }

  .window{
    padding-top:20%;
    padding-left:0%;
    padding-right:0%;
  }
  .header{
    font-size:2em;
  }

  .img-header{
    width:100vw;
    background-size:cover;
  }
}

</style>

<!-- References:

-- Skills Progress Bar
https://codepen.io/joshbivens/pen/mPRovV

-- Particlesjs
https://vincentgarreau.com/particles.js/

 -->

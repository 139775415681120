<template>
  <div id="container">
    <ContactCompPage/>
    <NavBar/>
  </div>
</template>

<script>
  import NavBar from '@/components/NavBar.vue'
  import ContactCompPage from '@/components/ContactCompPage.vue'
  
  export default {
      name: 'ContactPage',
      components: {
          ContactCompPage,
          NavBar
      }
  }
</script>

<style scoped>
#container{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
</style>
  
  